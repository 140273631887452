.jobdetails-form {
  margin-bottom: 2rem;

  &__row {
    display: flex;
    align-items: baseline;
    padding: 0.5rem 0;
    margin: 0 -0.25rem;
    flex-wrap: wrap;
  }

  &__field {
    padding: 0 0.25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1rem;
    min-width: 100%;

    > label {
      pointer-events: none;
    }

    #upload-cv-title, #upload-cover-letter-title, .count-attached {
      position: absolute;
      left: 44px;
      top: 24px;
      width: calc(100% - 48px);
      list-style: none;
      padding: 0;

      span:first-of-type {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      li > div {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        word-break: break-word;
      }

      &.notEmpty {
        padding: 7px 10px;
      }
    }

    .count-attached {
      &-label {
        display: none;
      }

      &.notEmpty {
        .count-attached-label {
          display: inline-block;
        }
      }
    }

    #upload-other-title {
      position: relative;
      list-style: none;
      padding-left: 0;
      margin-top: 10px;
      z-index: 1;

      li > div {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        word-break: break-word;
      }
    }

    .attachedIcon {
      position: absolute;
      top: 22px;
      left: 4px;
      padding: 4px 6px;
      background-color: #ff6600;
      fill: white;
      cursor: pointer;
      width: 40px;
      height: 40px;
      z-index: -1;
    }

    input[type='text'] {
      background-color: transparent;
    }

    @include breakpoint(not-mobile-portrait) {
      min-width: 50%;
      max-width: 50%;
    }

    @include breakpoint(not-mobile) {
      min-width: 33%;
      max-width: 33%;
    }

    @include breakpoint(desktop) {
      min-width: 20%;
      max-width: 20%;

      #upload-cv-title, #upload-cover-letter-title, .count-attached {
        top: 18px;
        left: 37px;
        width: calc(100% - 40px);
      }

      .attachedIcon {
        top: 18px;
        width: 33px;
        height: 33px;
      }
    }

    &--checkbox {
      flex-direction: row;
    }

    &--questions {
      min-width: 100%;
      max-width: 100%;

      &-item {
        width: 33%!important;
      }
    }
  }

  &__button {
    background-color: #437fa3;
    border-color: #437fa3!important;
    width: 8rem!important;
    padding: 0.5rem 1rem;
    color: white!important;
    cursor: pointer;
  }

  input:not([type='checkbox']),
  select,
  textarea,
  .jobdetails-form__file-preview {
    border: 2px dashed $orange;
    width: 100%;
  }

  input[required],
  select[required],
  textarea[required],
  input.required {
    border-style: solid;
  }

  input[type='email'],
  .jobdetails-form__file-preview {
    height: 2.5em;
    outline-color: $shaded-blue;
  }

  input[type='file'] {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 18px;
    bottom: 0;
    height: 45px;

    &::file-selector-button {
      cursor: pointer;
    }

    @include breakpoint(desktop) {
      height: 32.5px;
    }
  }

  input[type='checkbox'] {
    margin-right: 0.75rem;
  }

  select {
    height: 2.5em;
    display: inline-block;
    padding: 0.5em 1em;
    line-height: 1.5em;
    color: $base-font-color;
    outline-color: $shaded-blue;
  }

  ul {
    margin: 0;
    padding-left: 1rem;
  }

  &__field-required {
    color: $orange;
  }

  &__field--error {
    color: $red;
    margin-top: 10px;
    display: none;
  }

  #upload-cv,
  #upload-cover-letter,
  #upload-other {
    cursor: pointer;
  }
}